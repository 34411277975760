<template>
  <section class="mb-3">
    <div
      class="d-flex flex-wrap justify-space-around items-center py-5 flex-column flex-sm-row"
    >
      <span
        v-for="{ active, key } in icons"
        :key="key"
        @click="handlerSelectOrderType(key, updateOptionsIcons)"
      >
        <div
          :class="`icon-order__container rounded-lg my-3 mx-2 white--text text-center ${
            active ? 'lighten-1' : 'darken-2'
          }
          ${handlerCheckMenu(key) ? 'blue-grey' : 'bg-disabled'}`"
          elevation="20"
        >
          <!-- :class="bg" -->
          <base-img
            cover
            width="100px"
            :src="require(`@/assets/icons/${key}.webp`)"
            :aspect-ratio="1"
            :opacity="1.7"
          ></base-img>

          <h3>
            {{ $t(key) }}
          </h3>
        </div>
        <span class="text-center" v-if="!handlerCheckMenu(key)">
          <h4 style="color: #ff0000">No disponible</h4>
        </span>
      </span>
    </div>
  </section>
</template>
<script>
import { orderTypeName } from "@/store/modules/orderType/constNames";

export default {
  name: "SelectOrderTypeComponent",
  props: {
    defaultOrderType: { type: String, required: true },
    handlerCheckMenu: { type: Function, required: true },
    handlerSelectOrderType: { type: Function, required: true },
  },
  data() {
    return {
      icons: Object.keys(orderTypeName).map((key) => ({
        active: key === this.defaultOrderType,
        key,
      })),
    };
  },
  methods: {
    updateOptionsIcons(key) {
      this.icons = this.icons.map((icon) => {
        icon.active = icon.key === key;
        return icon;
      });
    },
  },
};
</script>

<style lang="sass">

.icon-order__container
  padding: 10px
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  transition: all 0.3s ease
    .icon-order:
      object-fit: cover
      width: 50px

.bg-disabled
  background-color: #ffcdd2
</style>
